import React from "react"
import ReactDOM from "react-dom"
import { useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from "react-router-dom"
import * as Sentry from "@sentry/react"
import { BrowserTracing } from "@sentry/tracing"
import App from "./App"
import makeServer from "./server"
import "./index.css"
import "@shopify/polaris/build/esm/styles.css"

const env = process.env.NODE_ENV
if (env === "development") {
    makeServer()
}

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
        new BrowserTracing({
            routingInstrumentation: Sentry.reactRouterV6Instrumentation(React.useEffect, useLocation, useNavigationType, createRoutesFromChildren, matchRoutes),
        }),
    ],
    tracesSampleRate: 0.2,
})

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById("root")
)
