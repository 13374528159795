import axios, { AxiosInstance, AxiosResponse, AxiosRequestConfig, AxiosError } from "axios"
import { ProductResp, SyncableProductCountResp } from "../models/product"
import { ShippingOptionMapping, ShippingOptionMappingResp, ShippingOptionMappingReq } from "../models/shippingOptionMapping"
import { CredentialsReq, SettingsResp, WizardCompletedReq, Credentials } from "../models/settings"
import { ShippingServiceResp } from "../models/shippingServices"
import { getSessionToken } from "@shopify/app-bridge-utils"
import { ClientApplication } from "@shopify/app-bridge"

declare module "axios" {
    interface AxiosResponse<T = any> extends Promise<T> {}
}

abstract class HttplClient {
    protected readonly instance: AxiosInstance
    private readonly app: ClientApplication<any>
    private readonly shop: string

    constructor(app: ClientApplication<any>, shop: string) {
        this.shop = shop
        this.app = app
        this.instance = axios.create({
            baseURL: process.env.REACT_APP_API_BASE_URL,
            withCredentials: true,
        })

        this._initializeRequestInterceptor()
        this._initializeResponseInterceptor()
    }

    private _initializeRequestInterceptor = () => {
        this.instance.interceptors.request.use(this._handleRequest, this._handleError)
    }

    private _initializeResponseInterceptor = () => {
        this.instance.interceptors.response.use(this._handleResponse, this._handleError)
    }

    private _handleRequest = async (config: AxiosRequestConfig) => {
        const token = await getSessionToken(this.app) // requires a Shopify App Bridge instance

        config.headers["Authorization"] = `Bearer ${token}`
        config.headers["Shop"] = this.shop
        return config
    }

    private _handleResponse = ({ data }: AxiosResponse) => data
    protected _handleError = (error: AxiosError) => {
        return Promise.reject(error)
    }
}

class ShopifyServiceApi extends HttplClient {
    public constructor(app: ClientApplication<any>, shop: string) {
        super(app, shop)
    }

    public async getSettings(): Promise<AxiosResponse<SettingsResp>> {
        return await this.instance.get("/setup/settings")
    }

    public async getShippingServices(): Promise<AxiosResponse<ShippingServiceResp>> {
        return await this.instance.get("/setup/shipping-services")
    }

    public async putCredentials(credentials: Credentials): Promise<AxiosResponse> {
        const req: CredentialsReq = {
            credentials,
        }
        return await this.instance.put("/setup/settings/client-credentials", req)
    }

    public async getProducts(limit: number, cursor: string, keyword: string, productStatus: string): Promise<AxiosResponse<ProductResp>> {
        const param_array = []
        if (limit && limit !== 50) param_array.push(["limit", limit])
        if (cursor) param_array.push(["cursor", cursor])
        if (keyword) param_array.push(["search", keyword])
        if (productStatus) param_array.push(["product-status", productStatus])
        const params = new URLSearchParams(param_array)

        return await this.instance.get("/setup/variants", { params })
    }

    public async postProductsSync(variantIDs: { [key: string]: string[] }): Promise<AxiosResponse> {
        return await this.instance.post("/setup/variants", { variants: variantIDs })
    }

    public async postProductsWithStockSync(enable: boolean, variantIDs: string[]): Promise<AxiosResponse> {
        return await this.instance.post("/setup/variants/stock-sync", { stock_sync_enabled: enable, variants: variantIDs })
    }

    public async postProductSync(variantID: string): Promise<AxiosResponse> {
        return await this.instance.post(`/setup/variants/${variantID}`)
    }

    public async postProductWithStockSync(enable: boolean, variantID: string): Promise<AxiosResponse> {
        return await this.instance.post(`/setup/variants/stock-sync/${variantID}`, { stock_sync_enabled: enable })
    }

    public async getProductCount(): Promise<AxiosResponse<SyncableProductCountResp>> {
        return await this.instance.get(`/setup/variants/count`)
    }

    public async getProductWithStockCount(): Promise<AxiosResponse<SyncableProductCountResp>> {
        return await this.instance.get(`/setup/variants/stock-sync/count`)
    }

    public async postSyncableProducts(): Promise<AxiosResponse> {
        return await this.instance.post(`/setup/variants/all`)
    }

    public async postSyncableProductStocks(enable: boolean): Promise<AxiosResponse> {
        return await this.instance.post(`/setup/variants/stock-sync/all`, { stock_sync_enabled: enable })
    }

    public async putCarrierMappings(mappings: Array<ShippingOptionMapping>): Promise<AxiosResponse> {
        const req: ShippingOptionMappingReq = {
            carrier_mappings: mappings,
        }

        return await this.instance.post("/setup/carrier-mappings", req)
    }

    public async getCarrierMappings(): Promise<AxiosResponse<ShippingOptionMappingResp>> {
        const mappings = (await this.instance.get("/setup/carrier-mappings")) as ShippingOptionMappingResp
        if (!mappings.carrier_mappings) {
            mappings.carrier_mappings = []
        }
        return mappings
    }

    public async putWizardCompletedStatus(wizard_completed: boolean): Promise<AxiosResponse> {
        const req: WizardCompletedReq = {
            wizard_completed,
        }
        return await this.instance.put("/setup/settings/wizard-completed", req)
    }
}

export default ShopifyServiceApi
