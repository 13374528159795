// import { inflections } from "inflected"
import { createServer, Model, Serializer, Response, Factory } from "miragejs"
import humps from "humps"

// inflections("en", function (inflect) {
//     inflect.irregular("variantWithStock", "variantsWithStock")
// })

const ApplicationSerializer = Serializer.extend({
    keyForCollection(modelName) {
        return this._container.inflector.pluralize(humps.decamelize(humps.camelize(modelName)))
    },
    keyForModel(modelName) {
        return this._container.inflector.pluralize(modelName)
    },
})

export default function mirageServer() {
    createServer({
        models: {
            carrierMapping: Model,
            variant: Model,
            setting: Model,
            shippingService: Model,
        },

        factories: {
            variant: Factory.extend({
                product_title: "iPod nano",
                variant_title(i) {
                    return `Variant ${i}`
                },
                image: {
                    id: 850703190,
                    alt_text: "iPod nano",
                    src: "https://cdn.shopify.com/s/files/1/0006/9093/3842/products/ipod-nano.png?v=1633120966",
                },
                product_id: "342916",
                sku(i) {
                    return `NANO${i.toString().padStart(3, "0")}`
                },
                sync_status: "NotSynced",
                sync_result: [],
                created: true,
                product_status(i) {
                    if (i % 2 === 0) {
                        return "active"
                    }
                    return "draft"
                },
                stock_sync_enabled: false,
            }),
        },

        seeds(server) {
            server.create("carrierMapping", {
                shopify_rate_name: "Bring Home Delivery Parcel",
                carrier_code: "BRING",
                service_type: "B2C",
                service_code: "PNL349",
                addon_codes: [],
                incoterms: [],
            })
            server.create("carrierMapping", {
                shopify_rate_name: "PostNord MyPack Collect",
                carrier_code: "POSTNORD",
                service_type: "B2C",
                service_code: "P19",
                addon_codes: [],
                incoterms: [],
            })
            server.create("variant", {
                id: "38010253050032",
                product_title: "Draumr 5.0",
                variant_title: "Standard length / Green",
                image: {
                    id: "gid://shopify/ProductImage/23384122654896",
                    alt_text: "Standard length / Green",
                    src: "https://cdn.shopify.com/s/files/1/0525/8566/0592/products/Flaylay_Back_DSCF0286_2048x2048_41961dc0-d9dd-47f0-9be6-705fe23598e1.png?v=1611565234",
                },
                product_id: "40103666516144",
                sku: "206C",
                sync_status: "Syncing",
                sync_result: [],
                created: false,
                product_status: "active",
                stock_sync_enabled: false,
            })
            server.create("variant", {
                id: "38010253050031",
                product_title: "Draumr 5.0",
                variant_title: "Standard length / Blue",
                image: {
                    id: "gid://shopify/ProductImage/23384122654896",
                    alt_text: "Standard length / Blue",
                    src: "https://cdn.shopify.com/s/files/1/0525/8566/0592/products/Flaylay_Back_DSCF0286_2048x2048_41961dc0-d9dd-47f0-9be6-705fe23598e1.png?v=1611565234",
                },
                product_id: "40103666516144",
                sku: "206C",
                sync_status: "Synced",
                sync_result: [],
                created: true,
                product_status: "active",
                stock_sync_enabled: false,
            })
            server.create("variant", {
                id: "808950810",
                product_title: "iPod nano",
                variant_title: "",
                image: {
                    id: 850703190,
                    alt_text: "iPod nano",
                    src: "https://cdn.shopify.com/s/files/1/0006/9093/3842/products/ipod-nano.png?v=1633120966",
                },
                product_id: "342916",
                sku: "NANO123",
                sync_status: "NotSynced",
                sync_result: [],
                created: true,
                product_status: "active",
                stock_sync_enabled: false,
            })
            server.create("variant", {
                id: "808950810",
                product_title: "cool prod",
                variant_title: "",
                image: {
                    id: 850703190,
                    alt_text: "iPod nano",
                    src: "https://cdn.shopify.com/s/files/1/0006/9093/3842/products/ipod-nano.png?v=1633120966",
                },
                product_id: "342916",
                sku: "NANO123",
                sync_status: "NotSynced",
                sync_result: [],
                created: true,
                product_status: "archived",
                stock_sync_enabled: false,
            })
            server.createList("variant", 97)
            server.create("setting", {
                credentials: { client_id: "sample_id", client_secret: "sample_secret" },
                wizard_completed: false,
            })
            server.create("shippingService", {
                carrier: {
                    name: "Postnord",
                    code: "POSTNORD",
                },
                services: {
                    B2C: [
                        {
                            name: "PostNord MyPack Collect",
                            code: "P19",
                            addon_codes: [
                                {
                                    name: "Begr. mängd farligt gods",
                                    code: "DNG",
                                },
                            ],
                            inco_terms: [],
                        },
                    ],
                    B2B: [],
                },
            })
            server.create("shippingService", {
                carrier: {
                    name: "Bring",
                    code: "BRING",
                },
                services: {
                    B2C: [
                        {
                            name: "Bring Home Delivery Parcel",
                            code: "PNL349",
                            addon_codes: [
                                {
                                    name: "Contract-ID",
                                    code: "IDCHKCTR",
                                },
                                {
                                    name: "Signature required",
                                    code: "SIGN",
                                },
                            ],
                            inco_terms: [],
                        },
                    ],
                    B2B: [],
                },
            })
            server.create("shippingService", {
                carrier: {
                    name: "CarrierWithIncoterms",
                    code: "carrier",
                },
                services: {
                    B2C: [
                        {
                            name: "test name",
                            code: "testcode",
                            addon_codes: [],
                            inco_terms: [{ name: "PLT", code: "PLT" }],
                        },
                    ],
                    B2B: [],
                },
            })
        },

        serializers: {
            application: ApplicationSerializer,
            currencyConversion: ApplicationSerializer.extend({
                attrs: ["to", "from", "rate"],
            }),
            setting: ApplicationSerializer.extend({
                attrs: ["credentials", "wizard_completed"],
            }),
        },

        routes() {
            // setting a namespace doesn't work for some reason
            // this.namespace = process.env.REACT_APP_API_BASE_URL
            const baseURL = process.env.REACT_APP_API_BASE_URL
            this.get(`${baseURL}/setup/carrier-mappings`)

            this.post(`${baseURL}/setup/carrier-mappings`, (schema, request) => {
                let attrs = JSON.parse(request.requestBody)
                schema.carrierMappings.all().destroy()
                console.log(attrs)
                console.log(request.requestHeaders)
                attrs.carrier_mappings.map(({ id, ...cm }) => schema.carrierMappings.create(cm))
            })

            // a standard function is used here instead of the arrow function to properly access the "this" object which contains the serialize method
            this.get(`${baseURL}/setup/variants`, function (schema, { queryParams }) {
                let variants

                const limit = queryParams.limit
                const cursor = queryParams.cursor
                const keyword = queryParams.search
                const productStatus = queryParams["product-status"]

                if (keyword)
                    variants = schema.variants.where((variant) => {
                        const { product_title, variant_title, sku } = variant
                        const target = (product_title + variant_title + sku).toLowerCase()

                        return target.includes(keyword.toLowerCase())
                    })
                else variants = schema.variants.all()

                if (productStatus) {
                    variants = variants.filter((variant) => {
                        return variant.product_status === productStatus
                    })
                }

                let page_length = 50
                let page_start = 0
                let next_cursor = "sample_cursor"

                if (limit) page_length = parseInt(limit)
                if (cursor === "sample_cursor") {
                    page_start = 50
                    next_cursor = ""
                }

                let json = this.serialize(variants)
                json["next_cursor"] = next_cursor
                json["variants"] = json.variants.slice(page_start, page_start + page_length)

                return json
            })

            this.post(`${baseURL}/setup/variants`, (schema, request) => {
                let attrs = JSON.parse(request.requestBody)
                console.log(attrs)
                console.log(request.requestHeaders)
                //this bulk sync request always makes the first variant successfully queue and fail the rest
                const productIDs = Object.keys(attrs.variants)
                const variantArray = productIDs.flatMap((prod_id) => attrs.variants[prod_id])
                const [firstVariant, ...remainingVariants] = variantArray
                schema.variants.find(firstVariant).update({
                    sync_status: "Synced",
                    sync_result: [],
                })

                remainingVariants.map((variant) =>
                    schema.variants.find(variant).update({
                        sync_status: "Failed",
                        sync_result: ["SKU can't be empty", "bla bla bla is missing"],
                    })
                )
            })

            this.post(`${baseURL}/setup/variants/stock-sync`, (schema, request) => {
                let attrs = JSON.parse(request.requestBody)
                console.log(attrs)
                console.log(request.requestHeaders)
                //this bulk sync request always makes the first variant successfully sync/desync and fail the rest
                const variantArray = attrs.variants ?? []
                const [firstVariant, ...remainingVariants] = variantArray
                schema.variants.find(firstVariant).update({
                    stock_sync_enabled: attrs.stock_sync_enabled ?? false,
                })

                const errors = {}
                remainingVariants.map((variant) => {
                    const targetVariant = schema.variants.find(variant)
                    return (errors[variant] = { product_title: targetVariant.product_title, variant_title: targetVariant.variant_title, errors: ["SKU can't be empty", "bla bla bla is missing"] })
                })

                return {
                    message: "Some products stock were unable to sync.",
                    error_type: "ValidationError",
                    errors,
                }
            })

            this.post(`${baseURL}/setup/variants/:id`, (schema, request) => {
                let id = request.params.id
                const targetVariant = schema.variants.find(id)
                console.log(id)
                //success
                // targetVariant.update({
                //     sync_status: "Syncing",
                //     sync_result: [],
                // })
                //error
                targetVariant.update({
                    sync_status: "Failed",
                    sync_result: ["SKU can't be empty", "bla bla bla is missing"],
                })
            })

            this.post(`${baseURL}/setup/variants/stock-sync/:id`, (schema, request) => {
                let id = request.params.id
                const attrs = JSON.parse(request.requestBody)
                const targetVariant = schema.variants.find(id)
                console.log(id)
                //success
                targetVariant.update({
                    stock_sync_enabled: attrs.stock_sync_enabled ?? false,
                })
                //error
                // return new Response(
                //     400,
                //     {},
                //     {
                //         message: "Unable to sync product.",
                //         error_type: "ValidationError",
                //         errors: { [id]: { product_title: targetVariant.product_title, variant_title: targetVariant.variant_title, errors: ["SKU can't be empty", "bla bla bla is missing"] } },
                //     }
                // )
            })

            this.get(`${baseURL}/setup/variants/count`, function (schema) {
                let variants = schema.variants.all()
                let syncedVariants = schema.variants.where((variant) => {
                    const { sync_status } = variant
                    return ["Synced", "Syncing"].includes(sync_status)
                })

                let json = {}
                json["count"] = variants.length
                json["synced"] = syncedVariants.length

                return json
            })

            this.get(`${baseURL}/setup/variants/stock-sync/count`, function (schema) {
                let variants = schema.variants.all()
                let syncedVariants = schema.variants.where((variant) => {
                    const { stock_sync_enabled } = variant
                    return stock_sync_enabled
                })

                let json = {}
                json["count"] = variants.length
                json["synced"] = syncedVariants.length

                return json
            })

            this.post(`${baseURL}/setup/variants/all`, (schema) => {
                schema.variants
                    .where((variant) => {
                        const { sync_status } = variant
                        return !["Synced", "Syncing"].includes(sync_status)
                    })
                    .update({
                        sync_status: "Syncing",
                        sync_result: [],
                    })
            })

            this.post(`${baseURL}/setup/variants/stock-sync/all`, (schema, request) => {
                let attrs = JSON.parse(request.requestBody)
                schema.variants.all().update({
                    stock_sync_enabled: attrs.stock_sync_enabled ?? false,
                })
            })

            this.get(`${baseURL}/setup/settings`, (schema) => {
                return schema.settings.first()
            })

            this.get(`${baseURL}/setup/shipping-services`, (schema) => {
                return schema.shippingServices.all()
            })

            this.put(`${baseURL}/setup/settings/client-credentials`, (schema, request) => {
                let attrs = JSON.parse(request.requestBody)
                console.log(attrs)
                console.log(request.requestHeaders)
                schema.settings.first().update({
                    credentials: { ...attrs.credentials },
                })
            })

            this.put(`${baseURL}/setup/settings/wizard-completed`, (schema, request) => {
                let attrs = JSON.parse(request.requestBody)
                console.log(attrs)
                console.log(request.requestHeaders)
                schema.settings.first().update({
                    wizard_completed: attrs.wizard_completed,
                })
            })
        },
    })
}
