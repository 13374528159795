import { createContext, useContext, useEffect, useMemo, useState } from "react"
import ShopifyServiceApi from "../services/shopifyServiceApi"
import { useAppBridge } from "@shopify/app-bridge-react"
import { AppContext } from "../context/appContext"

export interface ServiceApiContextType {
    serviceApi: ShopifyServiceApi
    setServiceApi: (input: ShopifyServiceApi) => void
}

interface ProviderProps {
    children: React.ReactChild
}

const ServiceApiContext = createContext<ServiceApiContextType | null>(null)

export const useServiceApi = () => useContext(ServiceApiContext)

export function ServiceApiProvider({ children }: ProviderProps): JSX.Element {
    const app = useAppBridge()
    const appContext = useContext(AppContext)

    const [serviceApi, setServiceApi] = useState<ShopifyServiceApi | null>(null)

    useEffect(() => {
        if (app && appContext.shop) setServiceApi(new ShopifyServiceApi(app, appContext.shop))
    }, [app, appContext])

    const shopifyServiceApi = useMemo((): ServiceApiContextType => ({ serviceApi, setServiceApi }), [serviceApi])

    return <ServiceApiContext.Provider value={shopifyServiceApi}>{children}</ServiceApiContext.Provider>
}
