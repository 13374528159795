import { Suspense, lazy } from "react"
import { Routes, Route, Navigate } from "react-router-dom"
import { Loading } from "@shopify/app-bridge-react"
import * as Sentry from "@sentry/react"
const Setup = lazy(() => import("../pages/setup"))
const Settings = lazy(() => import("../pages/settings"))
const ProductSync = lazy(() => import("../pages/productSync"))
const StockSync = lazy(() => import("../pages/stockSync"))
const Configuration = lazy(() => import("../pages/shippingServiceConfiguration"))
const Home = lazy(() => import("../pages/home"))
const Help = lazy(() => import("../pages/help"))

const SentryRoute = Sentry.withSentryReactRouterV6Routing(Route)

function AppRoutes() {
    return (
        <Suspense fallback={<Loading />}>
            <Routes>
                <SentryRoute path="/*" element={<Home />} />
                <SentryRoute path="/api/app" element={<Navigate to="/" replace />} />
                <SentryRoute path="/setup" element={<Setup />} />
                <SentryRoute path="/settings" element={<Settings />} />
                <SentryRoute path="/product-sync" element={<ProductSync />} />
                <SentryRoute path="/stock-sync" element={<StockSync />} />
                <SentryRoute path="/shipping-services" element={<Configuration />} />
                <SentryRoute path="/help" element={<Help />} />
                <SentryRoute path="*" element={<Navigate to="/" replace />} />
            </Routes>
        </Suspense>
    )
}

export default AppRoutes
