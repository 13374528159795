import React from "react"

export interface AppContextType {
    host: string
    shop: string
}

export const AppContext = React.createContext<AppContextType>({
    host: "",
    shop: "",
})
