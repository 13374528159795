import { NavigationMenu } from "@shopify/app-bridge-react"

const AppNav = (): JSX.Element => (
    <NavigationMenu
        navigationLinks={[
            {
                label: "Home",
                destination: "/",
            },
            {
                label: "Settings",
                destination: "/settings",
            },
            {
                label: "Product sync",
                destination: "/product-sync",
            },
            {
                label: "Stock sync",
                destination: "/stock-sync",
            },
            {
                label: "Shipping services",
                destination: "/shipping-services",
            },
            {
                label: "Setup Wizard",
                destination: "/setup",
            },
            {
                label: "Help",
                destination: "/help",
            },
        ]}
        matcher={(link, location) => link.destination === location.pathname}
    />
)

export default AppNav
