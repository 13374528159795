import { useMemo } from "react"
import { AppProvider } from "@shopify/polaris"
import translations from "@shopify/polaris/locales/en.json"
import { Provider } from "@shopify/app-bridge-react"
import { useLocation, useNavigate, BrowserRouter } from "react-router-dom"
import { AppContext, AppContextType } from "./context/appContext"
import AppRoutes from "./components/AppRoutes"
import AppNav from "./components/AppNav"
import { ServiceApiProvider } from "./context/serviceApiContext"
import "./App.css"
import { GetUrlParameter } from "./utils/browser"
import { AppConfigV2 } from "@shopify/app-bridge"

type AppProps = {
    appContextType: AppContextType
    config: AppConfigV2
}

function App({ appContextType, config }: AppProps) {
    const navigate = useNavigate()
    const location = useLocation()
    const history = useMemo(() => ({ replace: (path) => navigate(path, { replace: true }) }), [navigate])

    const router = useMemo(
        () => ({
            location,
            history,
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [location, navigate]
    )

    return (
        <AppProvider i18n={translations}>
            <Provider config={config} router={router}>
                <AppNav />
                <AppContext.Provider value={appContextType}>
                    <ServiceApiProvider>
                        <AppRoutes />
                    </ServiceApiProvider>
                </AppContext.Provider>
            </Provider>
        </AppProvider>
    )
}

export default function AppWrapper() {
    const shop = GetUrlParameter("shop")
    const host = GetUrlParameter("host")

    const appContextType: AppContextType = {
        host: host,
        shop: shop,
    }

    const config = { apiKey: process.env.REACT_APP_SHOPIFY_API_KEY, host: host, forceRedirect: true }

    return (
        <BrowserRouter>
            <App appContextType={appContextType} config={config} />
        </BrowserRouter>
    )
}
