function GetUrlParameter(key: string): string {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const result = urlParams.get(key)

    if (result === null) {
        return ""
    }

    return result
}

export { GetUrlParameter }
